import axios from "axios";
import { BACKEND_URL } from "../../react/config";

export const getSession = async () => {
    try {
        let response = await axios.request({
            url: `${BACKEND_URL}/api/v1/session`,
            withCredentials: true,
            headers: { "Content-Type": "application/json", Accept: "application/json" },
            method: "GET",
        });
        return response.data;
    } catch (e) {
        return null;
    }
};

export const getCSRF = () => {
    axios.request({
        url: `${BACKEND_URL}/sanctum/csrf-cookie`,
        withCredentials: true,
        method: "GET",
    });
};
